import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import * as _ from 'lodash';

import { RootState } from '@/redux';
import * as contactModels from '../models/contact';

export interface FormState {
  isLoading: boolean;
  data: contactModels.submitContactFormData | null;
  error: SerializedError | null;
  currentRequestId: string | undefined;
}

export const initialState: FormState = {
  isLoading: false,
  data: null,
  error: null,
  currentRequestId: undefined,
};

export const contactFormSelector = (state: RootState): FormState =>
  _.get(state, ['contact', 'form']);

export const submitContactForm = createAsyncThunk(
  'contact/form/submitContactForm',
  async (payload: contactModels.submitContactFormPayload, { getState, requestId }) => {
    const { currentRequestId, isLoading } = contactFormSelector(getState() as RootState);

    if (!isLoading || requestId !== currentRequestId) {
      return;
    }

    return contactModels.submitContactForm(payload);
  },
);

export const FormSlice = createSlice({
  name: 'contact/form',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(submitContactForm.pending, (state, action) => {
        if (!state.isLoading) {
          state.isLoading = true;
          state.error = null;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(submitContactForm.fulfilled, (state, action) => {
        if (state.isLoading && state.currentRequestId === action.meta.requestId) {
          state.isLoading = false;
          state.currentRequestId = undefined;

          if (!_.isUndefined(action.payload)) {
            state.data = action.payload;
          }
        }
      })
      .addCase(submitContactForm.rejected, (state, action) => {
        if (state.isLoading && state.currentRequestId === action.meta.requestId) {
          state.isLoading = false;
          state.data = null;
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export const actions = FormSlice.actions;

export default FormSlice.reducer;
