import config from 'common/config';

// You can change here to modify all the i18n related path
const _zh_tw = config.zh_TW;
const _zh_cn = config.zh_CN;
const _en_us = config.en_US;

export type LangCode = typeof _zh_tw | typeof _zh_cn | typeof _en_us;

export const constant = {
  zh_tw: _zh_tw as LangCode,
  zh_cn: _zh_cn as LangCode,
  en_us: _en_us as LangCode,
  PATH_TW: `/${_zh_tw}`,
  PATH_CN: `/${_zh_cn}`,
  PATH_EN: `/${_en_us}`,
};

export const APII18N = new Map();

APII18N.set('en', 'en-us');
APII18N.set('tw', 'zh-tw');
APII18N.set('cn', 'zh-cn');

export const localePath = {
  'zh-TW': constant.PATH_TW,
  [_zh_tw]: constant.PATH_TW,
  'zh-CN': constant.PATH_CN,
  [_zh_cn]: constant.PATH_CN,
  'en-US': constant.PATH_EN,
  [_en_us]: constant.PATH_EN,
};

export const routes = {
  HOME: `${config.webUrl}/`,
  SERVICES: `${config.webUrl}/global-payroll-and-employer-of-record`,
  REMOTE_JOBS: `${config.jobsUrl}/remote-jobs`,
  LOGIN: `${config.authUrl}`,
  FEATURE: `${config.webUrl}/features`,
  HELP: `${config.webUrl}/help`,
  CONTACT: `${config.webUrl}/contact`,
  ABOUT_US: `${config.webUrl}/about-us`,
  TERMS: `${config.webUrl}/terms`,
  PRIVACY: `${config.webUrl}/privacy`,
  PRICING: `${config.webUrl}/pricing`,
  REQUEST_FORM: `${config.webUrl}/request-form`,
  PAYROLL: `${config.webUrl}/payroll`,
  EOR: `${config.webUrl}/eor`,
};

export const CDN_HOST = 'https://storage.googleapis.com/sf-prod-assets-sz';
export const CONSOLE_HOST = config.apiBaseUrl;

export enum JobCategory {
  all = 'all',
  software = 'software',
  design = 'design',
  project = 'project',
  product = 'product',
  consultant = 'consultant',
  marketing = 'marketing',
  operations = 'operations',
  accountant = 'accountant',
  intern = 'intern',
  sales = 'sales',
  business_development = 'business_development',
}

export const screenSize = {
  XS: 480,
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
  XXL: 1600,
};

export const vercelEnv = {
  PRODUCTION: 'production',
  PREVIEW: 'preview',
  DEVELOPMENT: 'development',
};

export const homepageData = {
  COUNTRIES: '150+',
  CURRENCIES: '130',
};

export const langCodeMap: Map<string, string> = new Map();
langCodeMap.set('en', 'en');
langCodeMap.set('zh_CN', 'cn');
langCodeMap.set('zh_TW', 'tw');
