export type Config = {
  baseUrl: string;
  authUrl: string;
  officeUrl: string;
  webUrl: string;
  apiBaseUrl: string;
  jobsUrl: string;
  vercelEnv: string;
  recaptchaKey: string;
  oldWebUrl: string;
  blogUrl: string;
  en_US: string;
  zh_TW: string;
  zh_CN: string;
};

const Config = {
  baseUrl: process.env.baseUrl,
  authUrl: process.env.authUrl,
  officeUrl: process.env.officeUrl,
  oldWebUrl: process.env.oldWebUrl,
  webUrl: process.env.webUrl,
  blogUrl: process.env.blogUrl,
  apiBaseUrl: process.env.APIBaseUrl,
  jobsUrl: process.env.jobsUrl,
  vercelEnv: process.env.vercelEnv,
  recaptchaKey: process.env.recaptchaKey,
  en_US: process.env.en_US,
  zh_TW: process.env.zh_TW,
  zh_CN: process.env.zh_CN,
} as Config;

export default Config;
