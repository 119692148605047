import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export interface UtilState {
  isSelected: boolean;
  spinload: boolean;
}

const initialState: UtilState = {
  isSelected: false,
  spinload: false,
};

export const currencySlice = createSlice({
  name: 'currencySlice',
  initialState,
  reducers: {
    setIsSelecte: (state, action: PayloadAction<boolean>) => {
      state.isSelected = action.payload;
      state.spinload = false;
    },
    setIsclick: (state) => {
      state.spinload = true;
    },
  },
});

export const actions = currencySlice.actions;

export default currencySlice.reducer;
