import Head from 'next/head';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';

import type { LangCode } from 'common/utils/constant';
import { CDN_HOST, constant, localePath } from 'common/utils/constant';
import { getHrefLangUrl } from 'common/utils/location';

const { zh_tw, zh_cn, en_us } = constant;
const faviconPath = 'pub/img/logo/favico_v3.jpg';

const AppMeta = () => {
  const intl = useIntl();
  const langCode = intl.locale as LangCode;
  const router = useRouter();
  const pathName = router.asPath;

  return (
    <Head>
      <meta charSet="UTF-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      />
      <link rel="alternate" hrefLang="zh-Hant" href={getHrefLangUrl(localePath[zh_tw], pathName)} />
      <link rel="alternate" hrefLang="zh-Hans" href={getHrefLangUrl(localePath[zh_cn], pathName)} />
      <link rel="alternate" hrefLang="en" href={getHrefLangUrl(localePath[en_us], pathName)} />
      <link
        rel="alternate"
        hrefLang="x-default"
        href={getHrefLangUrl(localePath[en_us], pathName)}
      />
      <link rel="dns-prefetch" href="https://static.slasify.com" />
      <link rel="dns-prefetch" href={CDN_HOST} />
      <link rel="dns-prefetch" href="https://videos.slasify.com" />
      <link rel="shortcut icon" href={`${CDN_HOST}/${faviconPath}`} />
      <link rel="apple-touch-icon" href={`${CDN_HOST}/${faviconPath}`} />
      <link rel="apple-touch-icon" sizes="72x72" href={`${CDN_HOST}/${faviconPath}`} />
      <link rel="apple-touch-icon" sizes="114x114" href={`${CDN_HOST}/${faviconPath}`} />
      <link rel="apple-touch-icon" sizes="144x144" href={`${CDN_HOST}/${faviconPath}`} />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <meta property="og:url" content={getHrefLangUrl(`/${langCode}`, pathName)} />
      <meta property="fb:app_id" content="1216562931718117" />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Slasify" />
      <meta
        key="og_title"
        property="og:title"
        content={intl.formatMessage({
          id: 'meta.og.title',
          defaultMessage: 'Slasify｜We Satisfy Your Global EOR & Payroll Needs',
        })}
      />
      <meta
        key="og_image"
        property="og:image"
        content={`${CDN_HOST}/pub/img/meta/slasify_og_4.jpg`}
      />
      <meta
        key="description"
        name="description"
        content={intl.formatMessage({
          id: 'meta.seo.desc',
          defaultMessage:
            "Slasify is not just another Global EOR (Employer of Record) and Payroll service provider, our technology on the one-stop remote worker management platform allows us to disrupt the staffing industry. We have over 10,000 remote talents in our network with over 80% of which are in the IT sector and around 1,000 talents are currently deployed. We are currently working with companies that are listed on the Fortune 100. We handle all payroll tax headaches and local employees' compliance efficiently in 150+ countries, especially in the Greater China region, Taiwan, Singapore, Australia, and the USA.",
        })}
      />
      <meta
        key="og_description"
        property="og:description"
        content={intl.formatMessage({
          id: 'meta.seo.desc',
          defaultMessage:
            "Slasify is not just another Global EOR (Employer of Record) and Payroll service provider, our technology on the one-stop remote worker management platform allows us to disrupt the staffing industry. We have over 10,000 remote talents in our network with over 80% of which are in the IT sector and around 1,000 talents are currently deployed. We are currently working with companies that are listed on the Fortune 100. We handle all payroll tax headaches and local employees' compliance efficiently in 150+ countries, especially in the Greater China region, Taiwan, Singapore, Australia, and the USA.",
        })}
      />
      <meta
        name="norton-safeweb-site-verification"
        content="7004wwsww3yx8i65lbyn--qvwmdp9-beyrjhkrdai5n3j0g55qvuj6uzg8s1m2h1-aopd2kdw87gpd6evmxtbc9jlrse8jcwwt1edvc55fg6sny5hcj-6oere8nubh8r"
      />
    </Head>
  );
};

export default AppMeta;
