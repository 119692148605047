import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UtilState {
  isSupportWebp: boolean;
  isSupportWebpDetected: boolean;
}

const initialState: UtilState = {
  isSupportWebp: false,
  isSupportWebpDetected: false,
};

export const UtilSlice = createSlice({
  name: 'util',
  initialState,
  reducers: {
    setIsSupportWebp: (state, action: PayloadAction<boolean>) => {
      state.isSupportWebp = action.payload;
    },
    setIsDetected: (state, action: PayloadAction<boolean>) => {
      state.isSupportWebpDetected = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const actions = UtilSlice.actions;

export default UtilSlice.reducer;
