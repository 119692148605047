import config from 'common/config';
import type { LangCode } from 'common/utils/constant';
import type { IntlShape } from 'react-intl';

const { baseUrl } = config;

export const getHrefLangUrl = (langPath: string, pathName: string) => {
  if (pathName === '/') {
    return `${baseUrl}${langPath}`;
  }
  return `${baseUrl}${langPath}${pathName}`;
};

export const getHrefLangUrlByIntl = (intl: IntlShape, routePath: string) => {
  const langCode = intl.locale as LangCode;
  return routePath.replace(`${baseUrl}/`, `${baseUrl}/${langCode}/`);
};
/* export const getHrefInWindow = () => {
  if (typeof window !== 'undefined') {
    return window.location.href;
  }
  return '';
}; */

/*
// 為了解決history.push時URL改變但pathName不會改變的問題
export const getLangCodeInWindow = () => {
  const href = getHrefInWindow();
  if (href.includes(PATH_TW)) {
    return zh_TW;
  }

  if (href.includes(PATH_CN)) {
    return zh_CN;
  }

  if (href.includes(PATH_EN)) {
    return en_US;
  }

  return '';
};
*/

/* export const replaceLangUrl = (pathName: string, langPath: string) => {
  const langRegExp = new RegExp(`${zh_tw}|${zh_cn}|${en_us}`);
  return pathName.replace(langRegExp, `${langPath}`);
}; */

/* export const getHrefLangUrlInWindow = (langPath: string) => {
  return replaceLangUrl(getHrefInWindow(), langPath);
}; */

/* export const getBaseUrlInWindow = (): string => {
  if (typeof window !== 'undefined') {
    const { href, host } = window.location;
    const re = new RegExp(`^http.*${host}`);
    if (href.match(re)) {
      const matchs = href.match(re) as RegExpMatchArray;
      return matchs[0];
    }
  }
  return '';
}; */
