import { Layout } from 'antd';

import styles from './appContent.module.less';

type AppContentProps = {
  className?: string;
  children: React.ReactNode;
};
const { Content } = Layout;

const AppContent = ({ className, children }: AppContentProps) => {
  return <Content className={`${styles.content} ${className}`}>{children}</Content>;
};

export default AppContent;
