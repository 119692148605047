import { AxiosPromise, AxiosRequestConfig } from 'axios';
import { axiosV2API } from 'common/api/axios';

export type submitContactFormPayload = {
  name: string;
  dialCode?: string;
  phone?: string;
  email: string;
  content: string;
};
export type submitContactFormData = {
  status: {
    code: number;
    message: string;
  };
  timestamp: string;
};

export const submitContactForm = (
  payload: submitContactFormPayload,
  axiosConfig: AxiosRequestConfig = {},
): AxiosPromise<submitContactFormData> => {
  return axiosV2API.post(`/contact`, payload, axiosConfig);
};
