import contactReducer from '@/features/contact/redux';
import { currencyApi } from '@/services/currency';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import usersReducer from 'common/redux/reducers/users';
import { countriesApi } from 'common/services/countries';
import { webpackApi } from 'common/services/webpack';
import utilReducer from 'ui/AppLayout/UtilSlice';
import currencyReducer from 'ui/CurrencySelect/redux/currencySlice';

export const rootReducers = combineReducers({
  util: utilReducer,
  contact: contactReducer,
  [webpackApi.reducerPath]: webpackApi.reducer,
  [countriesApi.reducerPath]: countriesApi.reducer,
  [currencyApi.reducerPath]: currencyApi.reducer,
  currency: currencyReducer,
  user: usersReducer,
});

export const store = configureStore({
  reducer: rootReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(webpackApi.middleware)
      .concat(countriesApi.middleware)
      .concat(currencyApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
