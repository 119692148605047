import { config } from '@fortawesome/fontawesome-svg-core';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { IntlProvider, ReactIntlErrorCode } from 'react-intl';
import { Provider } from 'react-redux';

import { store } from '@/redux';
import useLoading from 'common/hooks/useLoading';
import AppLayout from 'ui/AppLayout';
import PageSpinner from 'ui/PageSpinner';

import '@fortawesome/fontawesome-svg-core/styles.css';
import 'antd/dist/antd.less';
import 'ui/styles/icons-v3.min.css';

import localeEN from '../locales/en.json';
import localeCN from '../locales/zh-CN.json';
import localeTW from '../locales/zh-TW.json';

import {
  InvalidConfigError,
  MessageFormatError,
  MissingDataError,
  MissingTranslationError,
  UnsupportedFormatterError,
} from '@formatjs/intl/src/error';
import { constant } from 'common/utils/constant';
import { FormatError } from 'intl-messageformat';

import 'ui/styles/globals.less';

config.autoAddCss = false;

const { en_us, zh_tw, zh_cn } = constant;

const onError = (
  e:
    | MissingTranslationError
    | MessageFormatError
    | MissingDataError
    | InvalidConfigError
    | UnsupportedFormatterError
    | FormatError,
) => {
  if (e.code === ReactIntlErrorCode.MISSING_DATA) {
    return;
  }
};

const SlasifyApp = ({ Component, pageProps }: AppProps) => {
  const { locale } = useRouter();
  const loading = useLoading();
  const shortLocale = locale ? locale : en_us;

  const messages = useMemo(() => {
    switch (shortLocale) {
      case en_us:
        return localeEN;
      case zh_tw:
        return localeTW;
      case zh_cn:
        return localeCN;
      default:
        return localeEN;
    }
  }, [shortLocale]);

  return (
    <Provider store={store}>
      <IntlProvider locale={shortLocale} messages={messages} onError={onError}>
        <AppLayout>{loading ? <PageSpinner /> : <Component {...pageProps} />}</AppLayout>
      </IntlProvider>
    </Provider>
  );
};

export default SlasifyApp;
