import Script from 'next/script';

const Zendesk = () => {
  return (
    <Script
      id="ze-snippet"
      src="https://static.zdassets.com/ekr/snippet.js?key=1037352d-0f81-4ce1-9356-82861f0bc0a3"
      strategy="beforeInteractive"
    />
  );
};

export default Zendesk;
