import { Spin } from 'antd';
import AppContent from '../AppContent';

import styles from './pageSpinner.module.less';

const PageSpinner = () => {
  return (
    <AppContent className={styles.pageSpinner}>
      <Spin size="large" />
    </AppContent>
  );
};

export default PageSpinner;
