import { Layout } from 'antd';
import config from 'common/config';
import { vercelEnv } from 'common/utils/constant';
import Script from 'next/script';
import CookieLawBanner from 'ui/AppLayout/CookieLawBanner';
import Zendesk from 'ui/AppLayout/Zendesk';
import AppMeta from './AppMeta';
import CyberChief from './CyberChief';

type AppLayoutProps = {
  children: React.ReactNode;
};

const AppLayout = ({ children }: AppLayoutProps) => {
  const isProduction = config.vercelEnv === vercelEnv.PRODUCTION;
  return (
    <Layout>
      <Script
        src={`https://www.recaptcha.net/recaptcha/api.js?render=${(config as any).recaptchaKey}`}
      />
      <AppMeta />
      {children}
      <CookieLawBanner />
      {isProduction && <CyberChief />}
      <Zendesk />
    </Layout>
  );
};

export default AppLayout;
