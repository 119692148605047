import axios from 'axios';
import config from 'common/config';
import {getCookie} from "../utils/cookie";

export function createAxiosV2APIsInstance(ifCsr = false) {
  const instance = axios.create({
    responseType: 'json',
    baseURL: ifCsr ? `/api` : `${config.apiBaseUrl}/v2`,
    withCredentials: true,
  });

  instance.interceptors.request.use(function (config) {
    if (typeof document !== 'undefined' && config.method === 'post') {
      const csrfToken = getCookie('Csrf-Token');
      if (csrfToken !== '') {
        config.headers = {
          'Csrf-Token': csrfToken
        }
      }
    }
    return config;
  }, function (error) {
    return Promise.reject(error);
  });

  return instance;
}

export const axiosV2API = createAxiosV2APIsInstance();

export const axiosV2APICsr = createAxiosV2APIsInstance(true);

export function createAxiosV2APIsOldInstance(ifCsr = false) {
  const instance = axios.create({
    responseType: 'json',
    baseURL: ifCsr ? '/oldApi' : `${config.apiBaseUrl}/api`,
    withCredentials: true,
  });

  instance.interceptors.request.use(function (config) {
    if (typeof document !== 'undefined' && config.method === 'post') {
      const csrfToken = getCookie('Csrf-Token');
      if (csrfToken !== '') {
        config.headers = {
          'Csrf-Token': csrfToken
        }
      }
    }
    return config;
  }, function (error) {
    return Promise.reject(error);
  });

  return instance;
}

// 请求旧版接口的 axios , 用于 getStaticProps 函数中，防止报错
export const axiosOldAPI = createAxiosV2APIsOldInstance();
// 请求旧版接口的 axios ，用于前端页面中的请求
export const axiosOldAPICsr = createAxiosV2APIsOldInstance(true);
