import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Country } from 'common/type/data.d';

export const countriesApi = createApi({
  reducerPath: 'countriesApi',
  baseQuery: fetchBaseQuery({ baseUrl: `/api` }),
  endpoints: (builder) => ({
    getCountries: builder.query<Country[], string>({
      query: (arg) => ({
        url: `/countries`,
        headers: {
          'Accept-Language': arg,
        },
      }),
      transformResponse: (response: { countries: Country[] }) => response.countries,
    }),

    getEorProvideCountries: builder.query<Country[], string>({
      query: (arg) => ({
        url: `/countries/eor-provide`,
        headers: {
          'Accept-Language': arg,
        },
      }),
      transformResponse: (response: { countries: Country[] }) => response.countries,
    }),
  }),
});

export const { useGetCountriesQuery, useGetEorProvideCountriesQuery } = countriesApi;
