import Link from 'next/link';
import CookieConsent from 'react-cookie-consent';
import { useIntl } from 'react-intl';

import { routes } from 'common/utils/constant';

import styles from './cookieLawBanner.module.less';

const CookieLawBanner = () => {
  const intl = useIntl();
  return (
    <CookieConsent
      buttonText={intl.formatMessage({
        id: 'cookie.law.accept.text',
        defaultMessage: 'I Understand',
      })}
      cookieName="sf_cookie_consent"
      containerClasses={styles.containerClass}
      contentClasses={styles.contentClass}
      buttonClasses={styles.buttonClass}
    >
      <div className={styles.contentText}>
        {intl.formatMessage({
          id: 'cookie.law.text',
          defaultMessage:
            'We use cookies to optimize site functionality and give you the best possible experience.',
        })}
      </div>
      <Link href={routes.PRIVACY}>
        <a className={styles.policyLink}>
          {intl.formatMessage({
            id: 'privacy.title',
            defaultMessage: 'Privacy Policy',
          })}
        </a>
      </Link>
    </CookieConsent>
  );
};

export default CookieLawBanner;
