import Script from 'next/script';

const CyberChief = () => {
  return (
    <Script
      type="text/javascript"
      src="https://secure.cyberchief.ai/cc-verify/f73b76ce8949fe29bf2a537cfa420e8f.js"
      integrity="sha256-xRKIWd5NHWeLiLFD6qN1ZsWYb260AMeJxmBo1VTduqo="
      crossOrigin="anonymous"
      strategy="beforeInteractive"
    />
  );
};

export default CyberChief;
