export const setCookie = (cname: string, cvalue: string, exdays = 7) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  console.log(
    `${cname}=${cvalue};${expires};path=/;domain=.${location.hostname
      .split('.')
      .splice(-2)
      .join('.')}`,
  );
  document.cookie = `${cname}=${cvalue};${expires};path=/;domain=.${location.hostname
    .split('.')
    .splice(-2)
    .join('.')}`;
};

export const getCookie = (cname: string): string => {
  const name = `${cname}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    const c = ca[i].trim();
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};
