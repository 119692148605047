import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {Webpack} from "../../type/data";

interface UserState {
  isLogin: boolean;
  userInfo?: Webpack;
}

const initialState: UserState = {
  isLogin: false,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setIsLogin: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload;
    },
    setUserInfo: (state, action: PayloadAction<Webpack>) => {
      state.userInfo = action.payload;
    },
  },
});

export const { setIsLogin, setUserInfo } = usersSlice.actions;
export default usersSlice.reducer;
